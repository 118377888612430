// import { useState, useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  bottom: 70vh;
  width: 100vw;
`

const VideoContainer = ({ children }: any) => {
  // const [render, setRender] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setRender(true)
  //   }, 3000)
  // }, [])

  // if (!render) return null

  return <Container>{children}</Container>
}

export default VideoContainer
