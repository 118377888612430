import { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const CursorAnimation = keyframes`
  0% { transform: rotate(260deg) }
  100% { transform: rotate(280deg) }
`

const CursorImg = styled.img.attrs(
  (props: { x: number; y: number; show: boolean }) => ({
    style: { left: props.x, top: props.y, opacity: props.show ? 1 : 0 },
  }),
)<{ x: number; y: number; show: boolean }>`
  position: fixed;
  pointer-events: none;
  width: 100px;
  height: 100px;
  transform: rotate(270deg);

  animation: 1s ${CursorAnimation} ease infinite alternate;
`

const Cursor = ({ show }: { show: boolean }) => {
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  useEffect(() => {
    window.addEventListener('mousemove', (e) => {
      setMouseX(e.x)
      setMouseY(e.y)
    })
  }, [])

  useEffect(() => {
    document.body.style.cursor = show ? 'none' : ''
  }, [show])

  return <CursorImg src="/img/spoon.svg" x={mouseX} y={mouseY} show={show} />
}

export default Cursor
