import styled, { keyframes } from 'styled-components'

const MopAnimation = () => keyframes`  
  0% { left: 11vw; }
  10% { left: 20vw; }
  20% { left: 15vw; }
  30% { left: 30vw; }
  40% { left: 27vw; }
  50% { left: 40vw; }
  60% { left: 35vw; }
  70% { left: 60vw; }
  80% { left: 54vw; }
  90% { left: 80vw; }
  100% { left: 72vw; }
`

const MopImg = styled.img`
  position: fixed;
  right: -20vw;
  bottom: 10px;

  animation: ${Math.random() * 2 + 8}s ${MopAnimation} ease-out infinite
    alternate;
`

const Mop = ({ show }: { show: boolean }) => (
  <MopImg src="/img/mop.gif" style={{ opacity: show ? 1 : 0 }} />
)

export default Mop
