import styled, { keyframes } from 'styled-components'
import { getRandomColor } from '../utils'

const SpotlightAnimation = () => {
  const size1 = Math.random() * 400 + 100
  const size2 = Math.random() * 400 + 100
  const size3 = Math.random() * 400 + 100
  return keyframes`
    0% {
      left: ${Math.random() * 110 - 20}vw;
      top: ${Math.random() * 110 - 20}vh;
      width: ${size1}px;
      height: ${size1}px;
      background: ${getRandomColor()};
    }
    50% {
      left: ${Math.random() * 110}vw;
      top: ${Math.random() * 110}vh;
      width: ${size2}px;
      height: ${size2}px;
      background: ${getRandomColor()};
    }
    100% { 
      left: ${Math.random() * 110}vw;
      top: ${Math.random() * 110}vh;
      width: ${size3}px;
      height: ${size3}px;
      background: ${getRandomColor()};
    }
  `
}

const Spotlight = styled.div<{
  size: number
  opacity: number
  delay: number
  duration: number
  color: string
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  opacity: ${(props) => props.opacity};
  border-radius: 999px;
  position: fixed;
  background: ${(props) => props.color};
  animation: ${(props) => props.duration}s ${SpotlightAnimation} ease-out
    infinite alternate;
  animation-delay: ${(props) => props.delay};
  pointer-events: none;
`
const BeamAnimation = () => {
  const offset = Math.random() * 30 + 35
  const topOffset1 = Math.random() * 20 + 20
  const topOffset2 = Math.random() * 20 - 20
  const topWidth1 = Math.random() * 30 + 10
  const topWidth2 = Math.random() * 30 + 10
  return keyframes`
    0% {
      clip-path: polygon(${topOffset1 + 50 + topWidth1}% 0, ${
    topOffset1 + 50
  }% 0, ${offset}% 100%, ${offset + 5}% 100%);
}
100% {
  clip-path: polygon(${
    topOffset2 + topWidth2
  }% 0, ${topOffset2}% 0, ${offset}% 100%, ${offset + 5}% 100%);
}
`
}
const Beam = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  opacity: 0.3;
  bottom: 0;
  left: 0;
  animation: 2s ${BeamAnimation} ease-in-out infinite alternate;
  pointer-events: none;
`

const Streamers = styled.img<{ i: number }>`
  width: 20vw;
  height: 20vw;
  position: fixed;
  top: 40px;
  left: ${(props) => props.i * 20}vw;
  pointer-events: none;
`

const SquigglesLeft = styled.img`
  width: 20vw;
  height: 20vw;
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: none;
  transform: rotate(260deg);
  pointer-events: none;
`
const SquigglesRight = styled.img`
  width: 20vw;
  height: 20vw;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
`

const Party = ({ show }: { show: boolean }) => (
  <div style={{ opacity: show ? 1 : 0 }}>
    {[...Array(5)].map((_, i) => (
      <Streamers src="/img/streamers.gif" i={i} />
    ))}
    {[...Array(3)].map(() => (
      <Beam />
    ))}
    {[...Array(3)].map(() => (
      <Spotlight
        color={getRandomColor()}
        size={Math.random() * 400 + 100}
        opacity={Math.random() * 0.3 + 0.2}
        delay={Math.random() * 2}
        duration={Math.random() * 3 + 1}
      />
    ))}
    <SquigglesLeft src="/img/squiggles.gif" />
    <SquigglesRight src="/img/squiggles.gif" />
  </div>
)

export default Party
