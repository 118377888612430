import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Container = styled.div<{ left?: number; show: boolean }>`
  position: fixed;
  margin: 10px;
  left: ${(props) => props.left || 0}px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: 40vw;
  transition: opacity 0.5s, left 0.5s;
  pointer-events: none;
`

const Video = ({ left, show, ...rest }: any) => {
  return (
    <Container left={left} show={show}>
      <ReactPlayer {...rest} />
    </Container>
  )
}

export default Video
