import styled, { keyframes } from 'styled-components'
import { getRandomColor } from '../utils'

const FlowerAnimation = (props: {
  startRotate: number
  endRotate: number
}) => keyframes`  
  from {
    transform: rotate(${props.startRotate}deg);
    left: -10vw; 
    bottom: ${Math.random() * 100}vh;
  }
  to {
    transform: rotate(${props.endRotate}deg);
    left: 110vw; 
    bottom: ${Math.random() * 100}vh;
  }
`

const Container = styled.div<{
  leftColor: string
  rightColor: string
  startRotate: number
  endRotate: number
}>`
  position: fixed;
  bottom: 0;
  right: -20vw;
  font-size: 72px;
  background: linear-gradient(
    to left,
    ${(props) => props.leftColor} -10%,
    ${(props) => props.rightColor} 77%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation-name: ${FlowerAnimation};
  animation-duration: ${Math.random() * 2 + 6}s;
`

const Flower = () => {
  const x = Math.random()
  let text = '✿'

  if (x < 0.2) {
    text = '🌼'
  } else if (x < 0.4) {
    text = '❃'
  } else if (x < 0.6) {
    text = '✾'
  } else if (x < 0.8) {
    text = '✽'
  }

  return (
    <Container
      leftColor={getRandomColor()}
      rightColor={getRandomColor()}
      startRotate={Math.random() * 30 - 30}
      endRotate={Math.random() * 30 - 30}
    >
      {text}FLOWER{text}
    </Container>
  )
}

export default Flower
