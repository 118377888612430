import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Confetti from './components/Confetti'
import Cursor from './components/Cursor'
import Flower from './components/Flower'
import Mop from './components/Mop'
import Party from './components/Party'
import Rainfall from './components/Rainfall'
import Video from './components/Video'
import VideoContainer from './components/VideoContainer'

const Container = styled.div<{ bg: string }>`
  height: 100vh;
  width: 100vw;
  background-size: cover;
`

const Background = styled.div<{ bgUrl: string; show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: url(${(props) => props.bgUrl});
  background-size: cover;
`

const AppContainer = styled.div`
  overflow: none;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-around;
  width: 200px;
  height: 80vh;
`

const VideoControls = styled.div`
  overflow: none;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  height: 10vh;
`

const Button = styled.button`
  font-family: monospace;
  font-size: 1.5em;
  height: 60px;
  padding: 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const ApartButton = styled(Button)`
  position: fixed;
  bottom: 15vh;
  width: 150px;
  left: calc(50vw - 75px);
`

const bgUrls = [
  '/img/1.jpg',
  '/img/2.jpg',
  '/img/3.jpg',
  '/img/4.jpg',
  '/img/5.jpg',
  '/img/6.jpg',
]

function App() {
  const [showRain, setShowRain] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [showFlower, setShowFlower] = useState(false)
  const [showCursor, setShowCursor] = useState(false)
  const [showMop, setShowMop] = useState(false)
  const [showParty, setShowParty] = useState(false)
  const [bgIndex, setBgIndex] = useState(0)
  const [videoApart, setVideoApart] = useState(false)
  const [showArr, setShowArr] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ])

  useEffect(() => {
    if (!showFlower) return
    const timeout = setTimeout(() => {
      setShowFlower(false)
    }, 5000)
    return () => clearTimeout(timeout)
  }, [showFlower])

  useEffect(() => {
    setShowFlower(false)
  }, [showConfetti, showRain, bgIndex])

  const [playing, setPlaying] = useState(false)
  const [pristine, setPristine] = useState(true)
  useEffect(() => {
    document.addEventListener('click', () => {
      if (pristine) {
        setPlaying(true)
      }
      setPristine(false)
    })
  }, [pristine])

  return (
    <Container bg={bgUrls[bgIndex]}>
      {bgUrls.map((url, i) => (
        <Background bgUrl={url} show={i === bgIndex} />
      ))}
      {showRain && <Rainfall />}
      {showFlower && <Flower />}
      <Mop show={showMop} />
      {showConfetti && <Confetti />}
      <Party show={showParty} />
      <VideoContainer>
        <Video
          left={
            videoApart ? 0 : window.innerWidth / 2 - window.innerWidth * 0.2
          }
          show={showArr[0]}
          playing={playing}
          url="/vid/1_PT_SK.webm"
          playsInline
          muted={!showArr[0]}
        ></Video>
        <Video
          left={
            videoApart
              ? window.innerWidth * 0.1
              : window.innerWidth / 2 - window.innerWidth * 0.2
          }
          show={showArr[1]}
          playing={playing}
          url="/vid/2_JT_XT.webm"
          playsInline
          muted={!showArr[1]}
        ></Video>
        <Video
          left={
            videoApart
              ? window.innerWidth * 0.2
              : window.innerWidth / 2 - window.innerWidth * 0.2
          }
          show={showArr[2]}
          playing={playing}
          url="/vid/3_Izzul.webm"
          playsInline
          muted={!showArr[2]}
        ></Video>
        <Video
          left={window.innerWidth * 0.3}
          show={showArr[3]}
          playing={playing}
          url="/vid/4_Ziyad.webm"
          playsInline
          muted={!showArr[3]}
        ></Video>
        <Video
          left={
            videoApart
              ? window.innerWidth * 0.4
              : window.innerWidth / 2 - window.innerWidth * 0.2
          }
          show={showArr[4]}
          playing={playing}
          url="/vid/5_Syimah.webm"
          playsInline
          muted={!showArr[4]}
        ></Video>
        <Video
          left={
            videoApart
              ? window.innerWidth * 0.5
              : window.innerWidth / 2 - window.innerWidth * 0.2
          }
          show={showArr[5]}
          playing={playing}
          url="/vid/6_Ila.webm"
          playsInline
          muted={!showArr[5]}
        ></Video>
        <Video
          left={
            videoApart
              ? window.innerWidth * 0.6
              : window.innerWidth / 2 - window.innerWidth * 0.2
          }
          show={showArr[6]}
          playing={playing}
          url="/vid/7_Shanice.webm"
          playsInline
          muted={!showArr[6]}
        ></Video>
      </VideoContainer>
      <VideoControls>
        {showArr.map((_, i) => (
          <Button
            key={i}
            onClick={() =>
              setShowArr((prev) => {
                const arr = [...prev]
                arr[i] = !prev[i]
                return arr
              })
            }
          >
            {showArr[i] ? 'hide' : 'show'}
          </Button>
        ))}
      </VideoControls>
      <ApartButton onClick={() => setVideoApart((prev) => !prev)}>
        {videoApart ? 'together' : 'apart'}
      </ApartButton>
      <AppContainer>
        <Button onClick={() => setShowRain((prev) => !prev)}>
          {showRain ? 'stop rain' : 'start rain'}
        </Button>
        <Button onClick={() => setShowConfetti((prev) => !prev)}>
          {showConfetti ? 'stop confetti' : 'start confetti'}
        </Button>
        <Button onClick={() => setShowMop((prev) => !prev)}>
          {showMop ? 'stop mop' : 'start mop'}
        </Button>
        <Button onClick={() => setShowParty((prev) => !prev)}>
          {showParty ? 'stop party' : 'start party'}
        </Button>
        <Button onClick={() => setShowFlower(true)}>flower</Button>
        <Button
          onClick={() =>
            setBgIndex((prev) => (prev === bgUrls.length - 1 ? 0 : prev + 1))
          }
        >
          bg
        </Button>
        <Button onClick={() => setShowCursor((prev) => !prev)}>cursor</Button>
      </AppContainer>
      <Cursor show={showCursor} />
    </Container>
  )
}

export default App
