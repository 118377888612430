import { useState } from 'react'
import styled from 'styled-components'
import { useInterval } from 'react-use'

function ShitRain() {
  const [emojisToRender, setEmojisToRender] = useState([
    { offset: 0, key: 0, emoji: '' },
  ])

  useInterval(() => {
    if (emojisToRender.length > 20) {
      emojisToRender.shift()
    }

    const offset = Math.floor(Math.random() * window.innerWidth * 0.9)
    const key = offset + Math.random() * 1000000
    const emoji = '|'

    emojisToRender.push({ offset, key, emoji })

    setEmojisToRender([...emojisToRender])
  }, 100)

  return (
    <SuperContainer>
      {emojisToRender.map(({ key, emoji, offset }) => {
        return (
          <EmojiContainer key={key} offset={offset}>
            {emoji}
          </EmojiContainer>
        )
      })}
    </SuperContainer>
  )
}

const SuperContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
`

const EmojiContainer = styled.div<{ offset: number }>`
  @keyframes falldown {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: 90vh;
    }
  }

  position: absolute;
  top: -100px;
  left: ${(props) => props.offset}px;

  font-size: 48px;
  color: rgba(255, 255, 255, 0.3);
  animation-name: falldown;
  animation-duration: 1s;
  animation-timing-function: linear;
`

export default ShitRain
